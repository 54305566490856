import { connectToParent } from 'penpal'

let lastCall = null
function throttle(func, milliseconds) {
  return () => {
    const shouldCall = lastCall === null || Date.now() - lastCall >= milliseconds
    if (shouldCall) {
      func()
      lastCall = Date.now()
    }
  }
}

window.addEventListener('load', function () {
  if (window === window.parent) {
    return
  }

  const appEl = document.getElementById('app-content-wrapper') || document.getElementById('app')

  appEl.style.overflowY = 'visible'
  appEl.style.display = 'block'
  appEl.style.height = 'auto'

  const sendPostMessage = throttle(() => {
    const height = appEl.offsetHeight
    const connection = connectToParent({
      parentOrigin: '*',
    })

    connection.promise.then((parent) => {
      parent.resize(height)
    })
  }, 5)

  setTimeout(sendPostMessage, 500)

  new ResizeObserver(sendPostMessage).observe(appEl)
})
